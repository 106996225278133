<template>
    <div class="box">
        <div class="project_Box">
            <div class="imgs">
                <img src="./images/banner.png" alt="">
            </div>
            <div class="imgs">
                <img src="./images/output.png" alt="">
            </div>
            <div class="imgs">
                <img src="./images/产品.jpg" alt="">
            </div>
            <div class="imgs">
                <img src="./images/outputaw.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortalIndex',

    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
img {
    width: 100%;
    height: 100%;
}

.box {
    width: 100%;
    background: #fff;
}

.project_Box {
    width: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imgs {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1200px;
    }

}
</style>