import Cookies from 'js-cookie'

const TokenKey = 'token'
const unsrInfo = 'unsrInfo'
const unsrInfo1 = 'unsrInfo1'
const unsrInfo2 = 'unsrInfo2'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setAccount(account) {
  return Cookies.set(unsrInfo, account, '7d')
}

export function setPassword(password) {
  return Cookies.set(unsrInfo1, password, '7d')
}

export function setAutoLogin(autoLogin) {
  return Cookies.set(unsrInfo2, autoLogin, '7d')
}

export function getAccount() {
  return Cookies.get(unsrInfo)
}

export function getPassword() {
  return Cookies.get(unsrInfo1)
}

