// import axios from 'axios'
import request from "@/utils/request";

// 获取学生菜单列表
export const GetCustomMenu = (TenantId) => {
  return request({
    method: "get",
    url: `/sysoauthuser/GetCustomMenu`,
    params: {
      TenantId,
    },
  });
};

// 获取专业咨询
export const GetType = (TypeId) => {
  return request({
    method: "get",
    url: `/busspecialistgroup/GetType`,
    params: {
      TypeId,
    },
  });
};

// 获取专家列表
export const GetList = () => {
  return request({
    method: "get",
    url: `/busspecialistgroup/list`,
  });
};

// 获取专家列表
export const Getdetail = (Id) => {
  return request({
    method: "get",
    url: `/busspecialistgroup/detail`,
    params: {
      Id,
    },
  });
};

// 获取特殊批次招生列表
export const GetStudentslist = () => {
  return request({
    method: "get",
    url: `/busspecialrecruitstudents/list`,
  });
};

// 获取生涯规划时间轴
export const GetCustomTimeAxis = () => {
  return request({
    method: "get",
    url: `/bustimeaxis/GetCustomTimeAxis`,
  });
};

// 获取出国留学国家
export const GetStudyAbroads = (TypeId) => {
  return request({
    method: "get",
    url: `/busstudyabroadtimeaxis/GetStudyAbroads`,
    params: {
      TypeId,
    },
  });
};

// 获取留学时间轴
export const GetBusStudyAbroadTimeAxes = (CountryId) => {
  return request({
    method: "get",
    url: `/busstudyabroadtimeaxis/GetBusStudyAbroadTimeAxes`,
    params: {
      CountryId,
    },
  });
};

// 获取量表类型列表
export const Getlista = (MenuId) => {
  return request({
    method: "get",
    url: `/busscale/list`,
    params: {
      MenuId,
    },
  });
};

// 获取量表试题
export const GetScaleQuestions = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetScaleQuestions`,
    params: {
      ScaleId,
    },
  });
};

// 提交量表结果保存集合
export const CustomBusScale = (data) => {
  return request({
    method: "post",
    url: `/busscale/CustomBusScale`,
    data,
  });
};

// 根据用户Id获取试题进度
export const getcustommaproccess = (CustomId) => {
  return request({
    method: "get",
    url: `/busscale/getcustommaproccess`,
    params: {
      CustomId,
    },
  });
};

// 获取我的报告列表
export const GetBusCustomReports = (CustomId) => {
  return request({
    method: "get",
    url: `/busscale/GetBusCustomReports`,
    params: {
      CustomId,
    },
  });
};

// 获取综合报告所需的id
export const GetReportMapIds = (ReportId) => {
  return request({
    method: "get",
    url: `/busscale/GetReportMapIds`,
    params: {
      ReportId,
    },
  });
};
// 获取霍兰德报告
export const GetHollandDimension = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetHollandDimension`,
    params: {
      ScaleId,
    },
  });
};

// 获取MBTI报告
export const GetMBTIDimension = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetMBTIDimension`,
    params: {
      ScaleId,
    },
  });
};

// 获取能力维度报告
export const GetAbilityDimension = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetAbilityDimension`,
    params: {
      ScaleId,
    },
  });
};

// 获取职业锚维度报告
export const GetOpinionAbout = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetOpinionAbout`,
    params: {
      ScaleId,
    },
  });
};

// 获取各个量表描述介绍
export const GetBusScaleDescriptiont = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetBusScaleDescription`,
    params: {
      ScaleId,
    },
  });
};

// 获取个人sas报告
export const GetCustomScaleExplains = (CustomScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetCustomScaleExplains`,
    params: {
      CustomScaleId,
    },
  });
};

// 获取学习风格报告
export const GetStudyStyle = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/GetStudyStyle`,
    params: {
      ScaleId,
    },
  });
};

// 获取留学问卷试题 340507046379589
export const getbusscalestudy = (ScaleId) => {
  return request({
    method: "get",
    url: `/busscale/getbusscalestudy`,
    params: {
      ScaleId,
    },
  });
};
