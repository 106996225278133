<template>
  <div class="box">
    <div class="leftBox">
      <img src="./images/leftLogo2.png" alt="" />
    </div>
    <div class="rightBox">
      <div class="title">山东深泉外国语学校智慧云平台</div>
      <div class="loginForm">
        <!-- 登录框 -->
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules">
          <el-form-item prop="account">
            <el-input
              prefix-icon="el-icon-user"
              v-model="loginForm.account"
              placeholder="请输入账号"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              show-password
              prefix-icon="el-icon-lock"
              v-model="loginForm.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <button @click="login">登录</button>
      </div>
    </div>

    <div class="company">由上海一草一木教育科技有限公司提供技术支持</div>
  </div>
</template>

<script>
import { validAccount } from "@/utils/validate"
// import { reqLogin } from "@/api/user"
export default {
  name: "HLogin",

  data () {
    const validateAccount = (rule, value, callback) => {
      console.log("我们要校验的用户名", value)
      if (!validAccount(value)) {
        callback(new Error("请输入正确的用户名"))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("请输入正确的密码"))
      } else {
        callback()
      }
    }
    return {
      radio: "1",
      loginForm: {
        account: "42092219910516",
        password: "123456",
      },
      loginRules: {
        //  validator自定义校验规则
        account: [
          { required: true, trigger: "blur", validator: validateAccount },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
    }
  },

  mounted () { },

  methods: {
    login () {
      // 手动兜底校验
      // this.$refs.loginForm.validate(async (valid) => {
      //   if (valid) {
      //     // await this.$store.dispatch("user/userLogin", this.loginForm);
      //     //登录
      //     const res = await reqLogin(this.loginForm)
      //     if (res.code == 200) {
      //       this.$message.success("登录成功~")
      //       this.$store.commit("user/setOtherUrl", res.data.otherUrl)
      //       this.$store.commit("user/setTokens", res.data.data)
      //       this.$store.commit("user/setTenantId", res.data.tenantId)
      //       this.$store.commit("user/setStudentInfo", res.data.studentInfo)
      this.$router.push("/SmartEducation")
      //     } else {
      //       // window.location.href = this.$store.state.user.otherUrl;
      //       this.$message.error("账号或者密码错误")
      //     }
      //   } else {
      //     return false
      //   }
      // })
    },
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  font-family: PingFangSC-Regular, PingFang SC;
  .leftBox {
    width: 50%;
    height: 100vh;
    background: radial-gradient(circle, #202047 0, #020917 100%);
  }
  .rightBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 50%;
    height: 100vh;
    background: #fff;
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #333333;
      margin: 228px 0 149px 0;
    }
    button {
      margin-top: 114px;
      width: 589px;
      height: 70px;
      background: #5474e6;
      border-radius: 35px;
      text-align: center;
      line-height: 70px;
      font-weight: bold;
      color: #ffffff;
      font-size: 24px;
      border: none;
    }
  }
  .company {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    color: #999999;
  }
}
::v-deep .el-form-item {
  margin-bottom: 50px;
}
::v-deep .el-input__inner {
  padding-left: 50px;
  font-size: 24px;
  height: 65px;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #d8d8d8 !important;
}
::v-deep .el-icon-user:before {
  font-size: 30px;
}
::v-deep .el-icon-lock:before {
  font-size: 30px;
}
::v-deep .el-icon-view:before {
  font-size: 30px;
}
::v-deep .el-input__prefix,
.el-input__suffix {
  top: 17px;
}
::v-deep .el-input__suffix {
  top: 17px;
}
</style>