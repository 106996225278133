import { setToken, removeToken, getToken } from "@/utils/auth.js";
// import { resetRouter } from "@/router";
// import { reqLogin } from "@/api/user";

export default {
  namespaced: true,
  state: {
    tenantId: JSON.parse(sessionStorage.getItem("setTenantId")) || "",
    studentInfo: JSON.parse(sessionStorage.getItem("studentInfo")) || "",
    // 优先从本地取token
    token: getToken() || null,
    otherUrl: "",
  },
  mutations: {
    // 用户id
    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
      sessionStorage.setItem("setTenantId", JSON.stringify(tenantId));
    },
    // 用户个人信息
    setStudentInfo(state, studentInfo) {
      state.studentInfo = studentInfo;
      sessionStorage.setItem("studentInfo", JSON.stringify(studentInfo));
    },
    // 删除用户id
    removeTenantId(state) {
      state.tenantId = "";
    },
    // 保存token
    setTokens(state, newToken) {
      state.token = newToken;
      // 本地保存一下
      setToken(newToken);
    },
    // 保存跳转地址
    setOtherUrl(state, newOtherUrl) {
      state.otherUrl = newOtherUrl;
    },
    // 删除token
    removeTokens(state) {
      state.token = null; // 删除vuex的token
      removeToken();
    },
  },
  actions: {
    logout(context) {
      context.commit("removeTokens");
      // 重置路由
      // resetRouter();
    },
    // 登录
    // async userLogin(context, data) {
    //   const res = await reqLogin(data);
    //   context.commit("setOtherUrl", res.otherUrl);
    //   context.commit("setTokens", res.data);
    //   context.commit("setTenantId", res.tenantId);
    //   context.commit("setStudentInfo", res.studentInfo);
    // },
  },
};
