<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // if (this._isMobile()) {
    //   // 跳转至手机端路由
    //   // alert("手机端");
    //   window.location.href = "http://h5.ycymedu.com/";
    // } else {
    //   // 跳转至 pc 端路由
    //   // alert("pc端");
    //   this.$router.replace("/");
    // }
  },
  methods: {
    // _isMobile() {
    //   let flag = navigator.userAgent.match(
    //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    //   );
    //   return flag;
    // },
    // 
  },
};
</script>

<style lang="less">
body {
  background: #f3f3f3;

  font-family: PingFangSC-Regular, PingFang SC !important;
}
</style>
