<template>
  <div class="box">
    <div class="logoBack">
      <img src="./images/编组 7备份@2x.png" alt="">
    </div>
    <el-dialog :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
      <div class="qrcode">
        <img :src="qrImg" alt="">
      </div>
      <div class="nac">{{ name }}</div>
    </el-dialog>
    <div class="project_Box" style="height: 100%;padding-bottom: 40px;">
      <div class="project" style="height: 100%;">
        <div class="title">一草一木智慧教育云平台</div>
        <div class="item" style="height: 600px;">
          <div class="imgs" style="font-size: 24px;text-indent: 2em;text-align: left;width: 35%;">
            上海一草一木教育科技有限公司位于上海教育科技高地，借助上海科创产业扶持政策，以高校科技成果转化为核心开展业务。公司与上海本地多所国内知名高校合作，充分发挥产、学、研融合优势，通过建立研究生实习基地、知名教授合作工作室等方式，为高校科技成果提供产业实践环境，同时解决教育科技产业需求，真正实现科研成果落地。
          </div>
          <div class="imgs" style="width: 59%;">
            <iframe :src="pdfUrl" type="application/pdf" frameborder="no" scrolling="auto" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box_five">
      <div class="project">
        <div class="title">生涯规划</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in projectList" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box_Two">
      <div class="project_Two">
        <div class="title_Two">智慧升学</div>
        <div class="item_Two">
          <div class="imgs_Two" v-for="(it, idx) in projectList_Two" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box">
      <div class="project">
        <div class="title">考研规划</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in projectList_three" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box_five box_four">
      <div class="project">
        <div class="title">智慧教学</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in projectList_five" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box box_six">
      <div class="project">
        <div class="title">智慧体育</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in projectList_six" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box ">
      <div class="project">
        <div class="title">智慧校园</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in projectList_four" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortalIndex',

  data() {
    return {
      pdfUrl: "https://www.ycymedu.com/%E4%B8%80%E8%8D%89%E4%B8%80%E6%9C%A8%E5%85%AC%E5%8F%B8%E7%AE%80%E4%BB%8B2023-12-15.pdf",
      dialogVisible: false,
      qrImg: '',
      name: '',
      projectList: [
        {
          type: 1,
          img: require('./images/生涯能力@2x.png'),
          url: 'http://sy.ycymedu.com/Career/Index?type=1&typename=%E5%AD%A6%E4%B9%A0%E8%83%BD%E5%8A%9B&v=0.11531225260493216',
        },
        {
          type: 1,
          img: require('./images/学生发展@2x.png'),
          url: 'http://sy.ycymedu.com/PsychMeasurement/Index?type=26&typename=%E5%A4%9A%E5%85%83%E6%99%BA%E8%83%BD%E6%B5%8B%E8%AF%84&v=0.6645308172812388&num=4',
        },
        {
          type: 1,
          img: require('./images/专业定位@2x.png'),
          url: 'http://sy.ycymedu.com/Direction/Index?type=2&v=0.9117760142463918&num=2',
        },
        {
          type: 1,
          img: require('./images/心理健康@2x.png'),
          url: 'http://sy.ycymedu.com/SelfofAnxiety/Index?type=42&typename=SAS%E6%B5%8B%E9%AA%8C&v=0.40954737569911037&num=10',
        },
        {
          type: 2,
          img: require('./images/学生发展备份@2x.png'),
          QrCode: require('./images/家长一起学.jpg'),
          name: '家长端'
        },
        {
          type: 1,
          img: require('./images/专业定位备份@2x.png'),
          url: 'http://sy.ycymedu.com/',
        },
      ],
      projectList_Two: [
        {
          type: 1,
          img: require('./images/高考选科@2x.png'),
          url: 'http://zy.ycymedu.com/newGaoKaoChooseSubjectV1/analyse?isAction=true&type=analyse&name=%E8%87%AA%E4%B8%BB%E5%88%86%E6%9E%90%E7%BB%93%E6%9E%9C&provinceCode=37&provinceName=%E5%B1%B1%E4%B8%9C&years=2024&modes=6_3&v=0.7643926068466198&num=5',
        },
        {
          type: 1,
          img: require('./images/志愿填报@2x.png'),
          url: 'http://zy.ycymedu.com/tzy/choosebatch?type=1&v=0.017196426309654456&num=3',
        },
        {
          type: 2,
          img: require('./images/志愿填报备份@2x.png'),
          QrCode: require('./images/六纬志愿.jpg'),
          name: '六纬志愿'
        },
        {
          type: 1,
          img: require('./images/出国留学@2x.png'),
          url: 'https://www.ycymedu.com/Home/StudyingAbroad',
        }
      ],
      projectList_three: [
        {
          type: 1,
          img: require('./images/考研规划简介@2x.png'),
          url: 'https://www.ycymedu.com/Home/MbaProject'
        },
        {
          type: 1,
          img: require('./images/考务成绩系统@2x.png'),
          url: 'https://p.ycymedu.com/'
        },
        {
          type: 2,
          img: require('./images/考研择校@2x.png'),
          QrCode: require('./images/六纬考研择校.jpg'),
          name: '六纬考研择校'
        },
        {
          type: 2,
          img: require('./images/六纬考研@2x.png'),
          QrCode: require('./images/六纬考研.jpg'),
          name: '六纬考研'
        },
      ],
      projectList_four: [
        {
          type: 1,
          img: require('./images/智慧办公@2x.png'),
          url: 'http://oa.ycymedu.com/x_desktop/index.html'
        },
        {
          type: 1,
          img: require('./images/编组 4@2x.png'),
          url: 'http://oa.ycymedu.com/x_desktop/index.html'
        },
        {
          type: 1,
          img: require('./images/编组 3@2x.png'),
          url: 'http://oa.ycymedu.com/x_desktop/index.html'
        },
        {
          type: 2,
          img: require('./images/编组 2@2x.png'),
          QrCode: require('./images/outpu2222t.png'),
          name: '智慧宿舍'
        },
      ],
      projectList_five: [
        {
          type: 1,
          img: require('./images/编组 5@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
        {
          type: 1,
          img: require('./images/编组 5备份.png'),
          url: 'https://basic.smartedu.cn/syncClassroom?defaultTag=e7bbb2de-0590-11ed-9c79-92fc3b3249d5%2Fe7bbd296-0590-11ed-9c79-92fc3b3249d5%2F6a749654-0772-11ed-ac74-092ab92074e6%2F44bee8bc-54e6-11ed-9c34-850ba61fa9f4%2Fff8080814371757b014390f883db0453'
        },
        {
          type: 1,
          img: require('./images/编组 6@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
        {
          type: 1,
          img: require('./images/编组 7@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
        {
          type: 1,
          img: require('./images/编组 7备份 2@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
        {
          type: 1,
          img: require('./images/编组 8@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
        {
          type: 1,
          img: require('./images/编组 9@2x.png'),
          url: 'http://47.101.154.132:8000/'
        },
      ],
      projectList_six: [
        {
          type: 1,
          img: require('./images/编组 10@2x.png'),
          url: 'https://www.ycymedu.com/Home/SmartSports'
        },
        {
          type: 1,
          img: require('./images/编组 11@2x.png'),
          url: 'http://sports.ycymedu.com/#/login  '
        },
        {
          type: 2,
          img: require('./images/编组 12@2x.png'),
          QrCode: require('./images/六纬体测.jpg'),
          name: '六纬体测'
        }
      ],
    };
  },

  mounted() {

  },

  methods: {
    goUrl(it) {
      console.log(it);
      if (it.type == 1) {
        window.open(it.url, '_blank')
      } else if (it.type == 2) {
        this.dialogVisible = true
        this.qrImg = it.QrCode
        this.name = it.name
      } else {
        this.$notify({
          title: '警告',
          message: it.type + '————————————错误',
          type: 'warning'
        });
      }
    },
    handleClose() {
      this.dialogVisible = false
    }
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  width: 100%;
}

::v-deep .el-dialog__headerbtn {
  top: 12px !important;
  cursor: pointer;
}

::v-deep .el-dialog {
  background: #fff !important;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
  height: 340px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrcode {
  width: 200px;
  height: 200px;
}

.nac {
  margin-top: 20px;
  font-size: 28px;
}

.logoBack {
  width: 100%;
  min-width: 1200px;
}

.box_four {
  background-image: url("./images/位图@2x.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;

}

.box_six {
  background-image: url("./images/编组 13@2x.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% auto !important;
}

.project_Box {
  width: 100%;
  min-width: 1200px;
  height: 462px;
  background: #FFFFFF;

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 80px;
      margin-bottom: 70px;
      min-width: 160px;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
    }

    .item {
      max-width: 1200px;
      min-width: 960px;
      display: flex;
      justify-content: space-around;

      .imgs {
        width: 20%;
        cursor: pointer;
      }
    }
  }

}

.project_Box_Two {
  width: 100%;
  min-width: 1200px;
  height: 462px;
  background-image: url("./images/编组@2x.png");
  background-size: 100% 462px;

  .project_Two {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title_Two {
      margin-top: 80px;
      margin-bottom: 70px;
      width: 160px;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
    }

    .item_Two {
      width: 1200px;
      min-width: 960px;
      display: flex;
      justify-content: space-around;

      .imgs_Two {
        width: 20%;
        cursor: pointer;
      }
    }
  }
}

.project_Box_five {
  min-width: 1200px;
  width: 100%;
  height: 582px;
  background: #F3F3F3;

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 80px;
      margin-bottom: 70px;
      width: 160px;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
    }

    .item {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .imgs {
        margin-bottom: 30px;
        min-width: 269px;
        cursor: pointer;
      }
    }
  }

}
</style>