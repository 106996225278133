<template>
  <div class="box">
    <div class="logoBack">
      <img src="./images/编组 7备份@2x.png" alt="">
    </div>
    <el-dialog :visible.sync="dialogVisible" width="25%" :before-close="handleClose">
      <div class="qrcode">
        <img :src="qrImg" alt="">
      </div>
      <div class="nac">{{ name }}</div>
    </el-dialog>
    <div class="project_Box">
      <div class="project">
        <div class="title">锦泽健康智慧养老服务平台</div>
        <div class="item" style="height: 600px;">
          <!-- <div class="imgs" v-for="(it, idx) in list" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div> -->
          <div class="imgs" style="font-size: 24px;text-indent: 2em;text-align: left;width: 35%;">
            智慧社区是一种新型的社区管理理念，充分利用物联网、云计算、移动互联网等新一代信息技术，为居民提供安全、舒适、便捷的现代化生活环境，形成信息化、智能化的管理模式。智慧社区建设不仅推动经济转型，还促进现代服务业发展。
            智慧社区将“智慧城市”的概念引入社区，以提升群众幸福感为目标，为居民提供便利，加快和谐社区建设，推动社会进步。基于物联网、云计算等高新技术的智慧社区是智慧城市的“细胞”，致力于打造以人为本的智能管理系统，使工作和生活更加便捷、舒适、高效。
          </div>
          <div class="imgs" style="width: 59%;">
            <iframe :src="pdfUrl" type="application/pdf" frameborder="no" scrolling="auto" width="100%" height="100%" />
          </div>
        </div>
        <!-- <div class="item1">
          <div class="imgs" @click="goUrl({type: 1,url: 'http://personscreen.jinzejk.com/'},)">
            <img src="./images/编组 3@2x.png" alt="">
          </div>
        </div> -->
      </div>
    </div>
    <div class="project_Box">
      <div class="project">
        <div class="title">智慧系统</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in list" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
        <div class="item1">
          <div class="imgs" @click="goUrl({
      type: 1,
      url: 'http://personscreen.jinzejk.com/'
    },)">
            <img src="./images/编组 3@2x.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box">
      <div class="project">
        <div class="title">智慧服务</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in list1" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="project_Box">
      <div class="project">
        <div class="title">智能硬件</div>
        <div class="item">
          <div class="imgs" v-for="(it, idx) in list2" :key="idx" @click="goUrl(it)">
            <img :src="it.img" alt="">
          </div>
        </div>
        <div class="item1">
          <div class="imgs" @click="goUrl({
      type: 1,
      url: '/Robots'
    })">
            <img src="./images/编组 3备份@2x.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortalIndex',

  data() {
    return {
      pdfUrl: "https://www.ycymedu.com/%E6%99%BA%E8%83%BD%E5%BA%B7%E5%85%BB%E6%99%BA%E6%85%A7%E7%A4%BE%E5%8C%BA.pdf",
      dialogVisible: false,
      qrImg: '',
      name: '',
      list: [
        {
          type: 1,
          img: require('./images/编组@2x.png'),
          url: 'http://demo.jinzejk.com/ '
        },
        {
          type: 1,
          img: require('./images/编组 2@2x.png'),
          url: 'http://largescreen.jinzejk.com/#/index'
        },
      ],
      list1: [
        {
          type: 2,
          img: require('./images/编组 4@2x.png'),
          QrCode: require('./images/锦泽守护.jpg'),
          name: '锦泽守护'
        },
        {
          type: 2,
          img: require('./images/编组 5@2x.png'),
          QrCode: require('./images/9f995e0e6a9e5d892ffc8466eca9810.jpg'),
          name: '锦泽康养'
        }
      ],
      list2: [
        {
          type: 2,
          img: require('./images/编组 6@2x.png'),
          QrCode: require('./images/微店小程序.pic.jpg'),
          name: '微店小程序'
        },
        {
          type: 1,
          img: require('./images/编组 5备份@2x.png'),
          url: '/Stereocartograph'
        }
      ],
    };
  },

  mounted() {

  },

  methods: {
    goUrl(it) {
      console.log(it);
      if (it.type == 1) {
        window.open(it.url, '_blank')
      } else if (it.type == 2) {
        this.dialogVisible = true
        this.qrImg = it.QrCode
        this.name = it.name
      } else {
        this.$notify({
          title: '警告',
          message: it.type + '————————————错误',
          type: 'warning'
        });
      }
    }
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  width: 100%;
  background: #F2F5FA;
}

::v-deep .el-dialog__headerbtn {
  top: 12px !important;
  cursor: pointer;
}

::v-deep .el-dialog {
  background: #fff !important;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
  height: 340px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrcode {
  width: 200px;
  height: 200px;
}

.nac {
  margin-top: 20px;
  font-size: 28px;
}

.logoBack {
  width: 100%;
  min-width: 1200px;
}

.project_Box {
  width: 100%;
  min-width: 1200px;

  .project {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-top: 80px;
      margin-bottom: 70px;
      min-width: 160px;
      height: 56px;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
      line-height: 56px;
    }

    .item {
      box-sizing: border-box;
      padding: 0 20px;
      width: 1200px;
      min-width: 960px;
      display: flex;
      justify-content: space-between;

      .imgs {
        width: 47%;
        cursor: pointer;
      }
    }

    .item1 {
      box-sizing: border-box;
      padding: 10px;
      margin-top: 40px;
      width: 1200px;
      min-width: 960px;

      .imgs {
        cursor: pointer;
        width: 100%;
      }
    }
  }

}
</style>