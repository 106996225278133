<template>
  <div class="box">
    <div :class="isShowName == 'pc端' ? 'content' : 'content phone'">
      <div class="top">
        <div class="leftLogo">
          <img src="./images/编组 2@2x.png" alt="" />
        </div>
        <div class="logoBox">
          <div class="logo" style="margin-right: 34px;">
            <img src="./images/一草一木logo白@2x.png" alt="" />
          </div>
          <div class="logo">
            <img src="./images/蓝色@2x.png" alt="" />
          </div>
        </div>
        <div class="rightLogo">
          <img src="./images/编组 3@2x.png" alt="" />
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="it in list" :key="it" @click="go(it.text)">
          <div class="bText">{{ it.text }}</div>
          <div class="cEng">点击进入</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoveWayCareerIndex',

  data() {
    return {
      isShowName: 'pc端',
      list: [
        {
          text: '一草一木智慧教育'
        },
        {
          text: '锦泽健康智慧养老'
        }
      ]
    }
  },

  mounted() {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      console.log('手机端')
      this.isShowName = '手机端'
      // this.$router.replace('/mb_index')
    } else {
      console.log('pc端')
      this.isShowName = 'pc端'
      // this.$router.replace('/pc_index')
    }
  },

  methods: {
    go(text) {
      if (text == '一草一木智慧教育') {
        this.$router.push("/EveryTreeAndBush")
      } else {
        this.$router.push("/ProvideForTheAged")
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
};
</script>

<style lang="less" scoped>
img {
  width: 100%;
  height: 100%;
}

.box {
  position: relative;
  min-width: 1200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  font-family: PingFangSC-Regular, PingFang SC;
}

.phone {
  height: 2000px !important;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 1200px;
  min-height: 1040px;
  height: 100vh;
  width: 100vw;
  background-image: url("./images/编组@2x.png");
  background-size: 100% auto !important;

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 113px;
  }

  .logoBox {
    margin: 0 78px;
    display: flex;

    .logo {
      height: 42px;
    }
  }


  .list {
    width: 1200px;
    height: 100%;
    margin-top: 200px;
    display: flex;
    justify-content: space-evenly;

    .item {
      transition: all 0.5s ease;
      box-sizing: border-box;
      // width: 633px;
      width: 40%;
      height: 50%;
      // height: 347px;
      background: #607bd4;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #ffffff;
      justify-content: center;



      .cEng {
        margin-top: 45px;
        text-align: center;
        line-height: 45px;
        width: 140px;
        height: 45px;
        background: #3964E6;
        border-radius: 10px;
        font-size: 24px;
      }

      .bText {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .item:hover>.cEng {
      background: #FFFFFF !important;
      color: #3964E6 !important;
    }

    .item:hover {
      transform: scale(1.11);
      background: #2e5bcb;
      cursor: pointer;
    }
  }


}
</style>