// import router from "@/router/index";
import axios from "axios";
// import store from "@/store";
// import NProgress from "nprogress";
import "nprogress/nprogress.css";
const request = axios.create({
  // 1.复用请求地址前缀
  // baseURL: "http://192.168.31.3:3002/api/",
  // baseURL: "http://47.103.130.88:3002/api/",
  // baseURL: "http://192.168.31.3:3002/api/",
  // 设置请求超时时间
  timeout: 5000,
  // 公共请求头
  headers: {},
});

// 请求拦截器
// request.interceptors.request.use(
//   // 成功
//   (config) => {
//     // 进度条展示
//     NProgress.start();
//     // (设置)复用公共请求参数
//     // config就是请求的配置对象
//     // config.params.xxx = xxx
//     // config.data.xxx = xxx
//     // config.headers.xxx = xxx
//     const token = store.state.user.token;
//     // 有token就带上
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     return config;
//   },
//   // 失败
//   () => {}
// );

// // 响应拦截器
// request.interceptors.response.use(
//   (response) => {
//     // 进度条结束
//     NProgress.done();
//     if (response.data.code === 401) {
//       // 功能失败
//       store.dispatch("user/logout");
//       // 回去login·
//       router.push("/login");
//       return Promise.reject(response.data.message);
//     }
//     if (response.data.code === 400) {
//       // 功能失败
//       store.dispatch("user/logout");
//       // 回去login·
//       router.push("/login");
//       return Promise.reject(response.data.message);
//     }
//     if (response.data.success) {
//       return response.data;
//     } else {
//       // 如果success为false 业务出错,直接触发reject
//       // 被catch分支捕获
//       // Promise.reject() // 生成一个失败状态的Promise
//       return response.data;
//     }
//   },
//   // 请求失败 - 失败
//   (error) => {
//     // 进度条结束
//     NProgress.done();
//     alert(error);
//     return Promise.reject(error);
//   }
// );

export default request;
