import Vue from "vue";
import VueRouter from "vue-router";
// import { Message } from "element-ui";
// import store from "../store";

// 引入组件
import Home from "@/views/Home";
import Login from "@/views/Login";
import SmartEducation from "@/views/SmartEducation";
import EveryTreeAndBush from "@/views/EveryTreeAndBush";
import ProvideForTheAged from "@/views/ProvideForTheAged";
import Robots from "@/views/Robots";
import Stereocartograph from "@/views/Stereocartograph";
// import Carrerpostion from "@/views/Home/carrerpostion";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: Login,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/SmartEducation",
      component: SmartEducation,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/EveryTreeAndBush",
      component: EveryTreeAndBush,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/ProvideForTheAged",
      component: ProvideForTheAged,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/Robots",
      component: Robots,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/Stereocartograph",
      component: Stereocartograph,
      meta: {
        isHiddenFooter: true,
      },
    },
    {
      path: "/",
      meta: { title: "学职生涯" },
      component: Home,
      redirect: "/SmartEducation",
      // children: [
      //   {
      //     path: "carrerpostion",
      //     component: Carrerpostion,
      //     meta: { title: "学职定位" },
      //   },
      // ],
    },
    {
      path: "/404",
      component: () => import("@/views/404.vue"),
      hidden: true,
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
  // 每次路由跳转触发函数
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

// 设置路由导航守卫
/*
  路由导航守卫：当路由跳转的时候触发的狗子函数
    1. 全局盗号守卫(对所有路由生效)
      beforeEach
      beforeResolve
      afterEach
    2. 路由导航守卫(只对当前路由生效)
    3. 组件导航守卫(只对当前组件生效)
*/

// 定义白名单
// const whiteList = ["/login", "/404"];

// 路由守卫
// router.beforeEach((to, from, next) => {
//   const token = store.state.user.token;
//   if (token) {
//     // 登录了
//     if (to.path === "/login") {
//       next("/");
//     } else {
//       // 登录之后访问其他页面都正常
//       next();
//     }
//   } else {
//     // 没有登录不能访问whiteList，要登录在进行访问
//     if (whiteList.includes(to.path)) {
//       Message.error("请登录在进行访问");
//       next();
//     } else {
//       // sessionStorage.clear();
//       next("/login");
//     }
//   }
// });

export default router;
