<template>
  <div class="content">
    <div class="box">
      <div class="right">
        <!--路由出口-->
        <transition name="el-fade-in-linear">
          <div class="main-content">
            <router-view></router-view>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomMenu } from "@/api/home"
export default {
  name: "XHome",
  components: {},
  async mounted() {
    const res = await GetCustomMenu(this.$store.state.user.tenantId)
    this.rootMenus = res.data.rootMenus
    this.studentInfo = this.$store.state.user.studentInfo
  },
  data() {
    return {
      rootMenus: null,
      studentInfo: null,
      num: 0,
      is: true,
    }
  },

  methods: {
    showsss(isCollapse) {
      this.is = !isCollapse
    },
    cut(index) {
      this.num = index
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;

  .typenav {
    width: 100%;
  }

  .box {
    width: 100%;
    display: flex;

    .right {
      width: 100%;
      margin: 32px;
    }
  }
}
</style>
